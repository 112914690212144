import {  gql } from '@apollo/client';

/**
 * @deprecated Migrated to rest call /article-comments/byArticle/{article id}
 */
export const getArticleRootComment = gql `
  # query AllArticleComment($article:ID, $parent:ID, $isParent:Boolean = true) {
  #   articleComments(sort:"id:desc", where:{article_comment_null:$isParent, article:$article,article_comment:$parent}){
  #     id,
  #     content,
  #     users_permissions_user{
  #       username,
  #     },
  #     created_at,
  #     article_comments{
  #       id
  #     }
  #   },
  #   articleCommentCount(where:{article_comment_null:$isParent, article:$article,article_comment:$parent}),
  #   articleCommentTotalCount:articleCommentCount(where:{ article:$article }),
  # }
  
  query AllArticleComment($article:ID, $parent:ID, $isParent:Boolean = true,$start:Int , $limit:Int, $splitDateTime:DateTime, $sortCol:String) {
    articleComments(limit:$limit, start:$start, sort:$sortCol, where:{virtual_article_comment_null:$isParent, article:$article,virtual_article_comment:$parent, created_at_lte:$splitDateTime}){
      id,
      content,
      users_permissions_user{
        id,
        username,
        displayName,
        isPaidMember,
      },
      created_at,
      # article_comments{
      #   id
      # },
      # article_comment{
      #   id,
      #   users_permissions_user{
      #       username,
      #       displayName
      #   },
      # },
      # users_like(limit: 9999){
      #   id
      # },
      virtual_article_comments{
        id
      }
      virtual_article_comment{
        id,
        users_permissions_user{
            username,
            displayName
        }
      }
    },
    # articleCommentCount( where:{virtual_article_comment_null:$isParent, article:$article,virtual_article_comment:$parent, created_at_lte:$splitDateTime}),
    articleCommentTotalCount:articleCommentCount(where:{ article:$article }),
  },

  # query AllArticleComment($article:ID, $parent:ID, $isParent:Boolean = true, $lastID:ID, $limit:Int, $splitDateTime:DateTime, $sortCol:String) {
  #   articleComments(limit:$limit sort:$sortCol, where:{virtual_article_comment_null:$isParent, article:$article,id_lt:$lastID,virtual_article_comment:$parent, created_at_lte:$splitDateTime}){
  #     id,
  #     content,
  #     users_permissions_user{
  #       username,
  #       displayName
  #     },
  #     created_at,
  #     article_comments{
  #       id
  #     },
  #     article_comment{
  #       id,
  #       users_permissions_user{
  #           username,
  #           displayName
  #       },
  #     },
  #     users_like(limit: 9999){
  #       id
  #     },
  #     virtual_article_comments{
  #       id
  #     }
  #     virtual_article_comment{
  #       id,
  #       users_permissions_user{
  #           username,
  #           displayName
  #       }
  #     }
  #   },
  #   articleCommentCount( where:{virtual_article_comment_null:$isParent, article:$article,virtual_article_comment:$parent, created_at_lte:$splitDateTime}),
  #   articleCommentTotalCount:articleCommentCount(where:{ article:$article }),
  # },
  
  # query AllArticleVirtualComment($article:ID, $parent:ID, $isParent:Boolean = true, $lastID:ID, $limit:Int, $splitDateTime:DateTime) {
  #   articleComments(limit:$limit sort:"id:desc", where:{virtual_article_comment_null:$isParent, article:$article,id_lt:$lastID,virtual_article_comment:$parent, created_at_lte:$splitDateTime}){
  #     id,
  #     content,
  #     users_permissions_user{
  #       username,
  #       displayName
  #     },
  #     created_at,
  #     article_comments{
  #       id
  #     },
  #     article_comment{
  #       id
  #     },
  #     users_like(limit: 9999){
  #       id
  #     },
  #     virtual_article_comments{
  #       id
  #     }
  #     virtual_article_comment{
  #       id
  #     }
  #   },
  #   articleCommentCount( where:{virtual_article_comment_null:$isParent, article:$article,virtual_article_comment:$parent, created_at_lte:$splitDateTime}),
  #   articleCommentTotalCount:articleCommentCount(where:{ article:$article }),
  # },
  

  # query AllArticleComment($article:ID, $parent:ID, $isParent:Boolean = true, $start:Int, $limit:Int, $splitDateTime:DateTime) {
  #   articleComments(limit:$limit, start:$start, sort:"id:desc", where:{article_comment_null:$isParent, article:$article,article_comment:$parent, created_at_lte:$splitDateTime}){
  #     id,
  #     content,
  #     users_permissions_user{
  #       username,
  #     },
  #     created_at,
  #     article_comments{
  #       id
  #     }
  #   },
  #   articleCommentCount(where:{article_comment_null:$isParent, article:$article,article_comment:$parent, created_at_lte:$splitDateTime}),
  #   articleCommentTotalCount:articleCommentCount(where:{ article:$article , created_at_lte:$splitDateTime}),
  # }
`

export const getAllArticleCommentUserLike = gql `
  query AllArticleCommentUserLike($article: ID, $userId:ID){
    articleCommentUserLikeIdList(where:{article:$article, users_like:$userId})
  }
`

export const addArticleRootComment = gql `
  mutation addArticleRootComment($content: String, $articleId: ID, $userID:ID, $parentArticleCommentID:ID ){
    createArticleComment(input:{ data:{content:$content, article:$articleId, users_permissions_user:$userID, article_comment:$parentArticleCommentID} }) {
      articleComment{
        id,
        article{
          id,
        }
        content,
        created_at,
        users_permissions_user{
          id,
          username,
          displayName,
          isPaidMember,
        },
        article_comment{
          id,
        },
        article_comments{
          id
        }
      }
    }
  }
`

export const addArticleCustomRootComment = gql `
  mutation addArticleCustomRootComment($content: String, $articleId: ID, $userID:ID, $parentArticleCommentID:ID, $reCaptchaToken:String, $virtualArticleCommentId: ID){
    articleCommentCustomCreate(
      content:$content, 
      article: $articleId, 
      users_permissions_user:$userID, 
      article_comment: $parentArticleCommentID, 
      virtual_article_comment: $virtualArticleCommentId,
      reCaptchaToken:$reCaptchaToken ){
        articleComment{
        id,
        article{
          id,
        }
        content,
        created_at,
        users_permissions_user{
          id,
          username,
          displayName,
          isPaidMember
        },
        article_comment{
          id,
          users_permissions_user{
            username,
            displayName
          }
        },
        article_comments{
          id
        },
        virtual_article_comment{
          id,
          users_permissions_user{
              username,
              displayName
          }
        }
      }
    }
  }
`

// export const getArticleRootComment = gql `
//   query AllArticleComment($article:ID) {
//     articleComments(where:{article_comment_null:true, article:$article}){
//       id,
//       content,
//       article_comments{
//         id
//       }
//     },
//     articleCommentCount(where:{article_comment_null:true, article:$article}),
//     articleCommentTotalCount:articleCommentCount(where:{ article:$article }),
//   }
// `

// export const getArticleSubComment = gql `
//   query ArticleSubComment($article:ID, $parent:ID){
//     articleComments(where:{article:$article, article_comment:$parent}){
//       id,
//       content,
//       article_comments{
//         id
//       }
//     },
//     articleCommentCount(where:{article:$article, article_comment:$parent}),
//     articleCommentTotalCount:articleCommentCount(where:{ article:$article }),
//   }
// `