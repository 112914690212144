import { gql } from '@apollo/client';
import { fragArticleWithImage, fragBasicImage } from './fragments';
export const getAllArticleAndCategorySlug = gql`
query AllArticleAndCategorySlug{
  articles (sort: "publishAt:desc", where:{category_null:false})  {
    category {
        slug
    },
    slug
  }
}
`

export const getHistorySlug = gql`
query HistorySlug($slug:String!){
  articleSlugHistories (where :{slug: $slug}, limit: 1 )  {
    article {
      slug
      category {
        slug
      }
    }
  }
}
`

export const getPaidArticleContent = gql`
query ArticleDetail($slug: String!){
  articles (where :{slug: $slug}, limit: 1 )  {
    id,
    created_at,
    updated_at,
    contentUpdatedAt
    content,
    slug,
  }
}
`

export const getArticleLikeCount= gql`
query ArticleLikeCount($slug: String!){  
  userLikedArticlesConnection (where:{article:{slug:$slug},  likeType_null:true }){
        aggregate {
          count
        }
      }
}
`

export const getArticleDetail = gql`
query IgnoreCacheArticleDetail($slug: String!){  
  userLikedArticlesConnection (where:{article:{slug:$slug},  likeType_null:true  }){
        aggregate {
          count
        }
      }
  articles (where :{slug: $slug}, limit: 1 ) {
    id,
    created_at,
    updated_at,
    contentUpdatedAt
    title,
    description,
    content,
    isAdult,
    slug,
    category {
      slug, 
      name,
      articleFooter
    },
    video{
      videoId,
      youtubeUrl,
      videoLength,
      vimeoId
    },
    image {
      id,
      size,
      url,
      alternativeText,
      formats,
      width,
      height
    },
    wideImage {
      id,
      size,
      url,
      alternativeText,
      formats,
      width,
      height
    },
    author {
      name,
      picture {
        id,
        size, 
        url,
        alternativeText
      },
      id
    },
    publishAt
    published_at,
    articleTags {
      name,
      slug
    },
    paidMemberOnly,
    displayComment,
    allowNewComment,
    relatedArticles (sort: "publishAt:desc"){
      ...ArticleWithImage
    }
  }
}
${fragArticleWithImage}
${fragBasicImage}
`

export const getRecentArticle = gql`
query RecentArticle($start: Int){
  articles (limit: 6,start:  $start, sort: "publishAt:desc")  {
    ...ArticleWithImage
  }
}
${fragArticleWithImage}
${fragBasicImage}
`
export const getRecentArticleOfCategories = gql`
query RecentArticle($start: Int, $catIds: [Int]){
  articles (limit: 6,start:  $start, sort: "publishAt:desc", 
  where:{category_in:$catIds }
  )  {
    ...ArticleWithImage
  }
}
${fragArticleWithImage}
${fragBasicImage}
`

export const getArticleById = gql`
query ArticleById($id:ID!){
    articles (where:{id:$id}, limit:1){
      slug
      category {
        slug
      }
    }
}
`