import { gql } from '@apollo/client';
import { fragArticleWithImage, fragBasicImage } from './fragments';
import { fragComponentSectionsArticleSlider, fragComponentSectionsImageBanner, fragComponentSectionsTextBlock, fragComponentSectionsArticleListing, fragComponentSectionsPartnerListing } from './sections';


export const getAllCategorySlug = gql`
query Query {
	categories {
		slug
  }
}
`

export const getCategory = gql`
query Query ($slug: String!){
	categories(where :{slug: $slug}, limit: 1)  {
		slug
		name
		description
		articles (limit: 9,start:  0, sort: "publishAt:desc") {
           ...ArticleWithImage
          }
		enableCustomPage
		customPageBlocks {
      __typename
			... on ComponentSectionsArticleSlider{
        ... ComponentSectionsArticleSlider
      }
						
			... on ComponentSectionsImageBanner{
        ... ComponentSectionsImageBanner
      }
			... on ComponentSectionsTextBlock{
        ... ComponentSectionsTextBlock
      }
      ... on ComponentSectionsArticleListing{
        ... ComponentSectionsArticleListing
      }
      ... on ComponentSectionsPartnerListing{
        ... ComponentSectionsPartnerListing
      }
		}
  }
}

  ${fragComponentSectionsArticleSlider}
  ${fragComponentSectionsImageBanner}
  ${fragComponentSectionsTextBlock}
  ${fragComponentSectionsArticleListing}
  ${fragComponentSectionsPartnerListing}
${fragArticleWithImage}
${fragBasicImage}
`

export const getCategorySlider = gql`
	query Query ($slug: String!){
		categories(where :{slug:$slug}){
			sliderArticles {
				category{
          slug
        },
				publishAt,
				published_at,
				paidMemberOnly
				title,
				slug,
				image {
			  	id,
					size,
					url,
					alternativeText,
		  		formats,
      width,
      height
				},
				wideImage {
					id,
					size,
					url,
					alternativeText,
					formats,
      width,
      height
    		},
			}
		}
	}
`

// sliderArticles{
// 	title,
// 	slug,
// 	image {
// 			id,
// 		size,
// 		url,
// 		alternativeText,
// 	formats,
// 	},
// 	wideImage {
// 			id,
// 		size,
// 		url,
// 		alternativeText,
// 	formats,
// 	},
// }

export const getRecentCategoryArticleBrief = gql`
query CategoryArticle($slug: String!, $currentArticleSlug: String){
	categories (where :{slug: $slug}, limit: 1) {
		slug,
		name,
	  articles (where:{slug_ne: $currentArticleSlug}, limit: 10, sort: "publishAt:desc")  {
			slug,
			id,
			title,
			isAdult,
			category {
		  		slug, 
				name
			},
    video{
videoId,
youtubeUrl,
videoLength,
vimeoId
    },
			image {
			  	id,
				size,
				url,
				alternativeText,
		  formats,
      width,
      height
			},
    wideImage {
      id,
      size,
      url,
      alternativeText,
      formats,
      width,
      height
    },
    	publishAt
    	published_at
			paidMemberOnly
	  }
  }
}
`

export const getCategoryArticle = gql`
query CategoryArticle($slug: String!, $start: Int){
	categories (where :{slug: $slug}, limit: 1) {
		slug,
		name,
	  	articles (limit: 6,start:  $start, sort: "publishAt:desc")  {
			slug,
			id,
			title,
			description,
			category {
		  		slug, 
				name
			},
    video{
		videoId,
youtubeUrl,
		videoLength,
vimeoId
    },
			image {
			  	id,
				size,
				url,
				alternativeText,
		  formats,
			},
			wideImage {
			  	id,
				size,
				url,
				alternativeText,
		  formats,
			},
			author {
			  	name,
  			  id
			},
    	publishAt
			published_at,
			articleTags {
			  	name,
				slug
			}
			paidMemberOnly
	  },
  }
}
`
export const getCategoryArticleWithLimit = gql`
query CategoryArticle($slug: String!, $start: Int, $limit:Int){
	categories (where :{slug: $slug}, limit: 1) {
		slug,
		name,
	  	articles (limit: $limit,start:  $start, sort: "publishAt:desc")  {
			slug,
			id,
			title,
			description,
			category {
		  		slug, 
				name
			},
    video{
		videoId,
youtubeUrl,
		videoLength,
vimeoId
    },
			image {
			  	id,
				size,
				url,
				alternativeText,
		  formats,
			},
			wideImage {
			  	id,
				size,
				url,
				alternativeText,
		  formats,
			},
			author {
			  	name,
  			  id
			},
    	publishAt
			published_at,
			articleTags {
			  	name,
				slug
			}
			paidMemberOnly
	  },
  }
}
`