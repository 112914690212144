import React, { FC, SVGProps, useEffect, useState } from 'react';
import IconStrokeOnly from 'public/images/icon/channelc-stroke-only.svg';
import { useSpring, animated } from 'react-spring';

const PathLength=932.171142578125;
export const LoadingIcon:FC<any>=(props)=>{
  const {...rest} = props;    
  const animatedStyle = useSpring({
    from: {
      strokeDashoffset: 0
    },
    to: {
      strokeDashoffset: PathLength*2
    },
    config:{
      duration:5000,
    },
    loop:true
  });
  return <svg width="180" height="180" viewBox="0 0 180 180" >
    <animated.path 
      {...animatedStyle}
      stroke="#939597" strokeDasharray={PathLength/2} strokeWidth={2}  fill="none"
      d="m89.783 3.6096a85.995 85.995 0 0 0-84.474 101.84c9.2852-11.109 19.299-26.55 22.47-30.545 4.6612-5.8701 18.191-19.534 22.672-23.825 4.481-4.2915 14.154-19.189 17.934-24.359 3.7803-5.1701 7.8161-5.5158 10.185-5.2478 2.3687 0.26802 26.509 5.2827 26.509 5.2827a18.686 18.686 0 0 1 2.4316 0.37523c6.2266 1.5009 10.429 6.7712 20.157 12.663 9.728 5.8918 0.33327 11.229-3.0026 12.452-3.3359 1.2228-21.836-4.8594-21.836-4.8594-5.0924 2.6235-15.266 0.67744-15.266 0.67744-0.71162 6.3152-12.231 23.403-12.231 23.403-0.88952 7.8938-10.565 22.791-10.83 25.16-0.26414 2.3687 3.5674 17.989 7.7687 27.508 4.2013 9.5183 23.405 7.9824 27.44 7.6382 4.0351-0.34416 18.346-6.7254 31.204 2.0199 12.858 8.7453-9.8236 13.984-14.038 15.905-4.2145 1.9212-12.03 7.4378-16.066 7.782-4.0359 0.34416-35.089-0.75745-41.149 3.3561-2.1652 1.4698-6.0355 3.1036-10.397 4.5292a85.952 85.952 0 1 0 40.519-161.76z"/>
  </svg>
}