import { IconButtonProps } from "@mui/material";
import React from "react";
// import {ShareButton} from '../ShareButton';
import dynamic from "next/dynamic";
import { Article } from "@/lib/remoteGraphQL";
const ShareButton = dynamic(()=>import("@/components/ShareButton"), {
  ssr: false,
})

const ArticleShareButton = (props: { article: Article } & IconButtonProps &{utmMedium?:string,btnWithLabel?:boolean}) => {
  const {article,utmMedium, ...rest}=props;
  return <ShareButton model={article} url={`/a/${article.id}`} searchParams={{utm_source:"web", utm_medium:utmMedium??"ccshare"}}  {...rest}/>
}


export default ArticleShareButton;