import {  gql } from '@apollo/client';

export const fragBasicImage = gql`
fragment BasicImage on UploadFile{
  url
  formats
  alternativeText
      width
      height
}
`;
export const fragBasicCategory = gql`
fragment BasicCategory on Category{
  slug
  name
}
`;
export const fragArticleWithImage =gql`
fragment ArticleWithImage on Article{
  id
  title
  description
  slug
  publishAt
  published_at
  contentUpdatedAt
  category{
  	...BasicCategory
  }
  image{
    ...BasicImage
  }
    wideImage {
    ...BasicImage
    },
  video{
    videoId,
    youtubeUrl,
    videoLength,
    vimeoId
  }
  paidMemberOnly
}
${fragBasicImage}
${fragBasicCategory}
`;
export const fragBasicHero =gql`
fragment BasicHero on ComponentSectionsHero{
  title
  content
  imagePosition
  detailUrl
  image{
    ...BasicImage
  }
}
${fragBasicImage}
`