import {  gql } from '@apollo/client';
import { fragArticleWithImage, fragBasicCategory, fragBasicHero, fragBasicImage } from './fragments';


export const fragComponentSectionsCategoryInsight = gql`
fragment ComponentSectionsCategoryInsight on ComponentSectionsCategoryInsight{
        title
        images{
          ...BasicImage
        }
        category{
          ...BasicCategory
        }
        heros{
          ...BasicHero
        }
}
${fragBasicImage}
${fragBasicCategory}
${fragBasicHero}
`;

export const fragComponentSectionsCategoryOverview = gql`
fragment ComponentSectionsCategoryOverview on ComponentSectionsCategoryOverview{
        title
        category{
          ...BasicCategory,
          articles (limit: 5,start:  0, sort: "publishAt:desc") {
           ...ArticleWithImage
          }
        }
        articles (limit: 9,start:  0, sort: "publishAt:desc") {
         ...ArticleWithImage
        }
}
${fragArticleWithImage}
${fragBasicCategory}
`;

export const fragComponentSectionsHero = gql`
fragment ComponentSectionsHero on ComponentSectionsHero{
        ...BasicHero
}
${fragBasicHero}
`;

export const fragComponentSectionsImageBanner = gql`
fragment ComponentSectionsImageBanner on ComponentSectionsImageBanner{
        url
        image {
          ...BasicImage
        }
        mobileImage {
          ...BasicImage
        }
}
${fragBasicImage}
`;

export const fragComponentSectionsHotTopics = gql`
fragment ComponentSectionsHotTopics on ComponentSectionsHotTopics{
        title
        arrayOfTags
        infiniteScroll
        categories {
          id
        }
        articles (limit: 9,start:  0, sort: "publishAt:desc") {
          ...ArticleWithImage
        }
}
${fragArticleWithImage}
`;

export const fragComponentSectionsArticleSlider = gql`
fragment ComponentSectionsArticleSlider on ComponentSectionsArticleSlider{
        title
        itemCount
        category{
          ...BasicCategory
          articles (limit: 9,start:  0, sort: "publishAt:desc") {
           ...ArticleWithImage
          }
        }
        articles (limit: 9,start:  0, sort: "publishAt:desc") {
         ...ArticleWithImage
        }
        autoScrollDelay
        autoScroll
}
${fragArticleWithImage}
${fragBasicCategory}
`;

export const fragComponentSectionsTextBlock = gql`
fragment ComponentSectionsTextBlock on ComponentSectionsTextBlock{
        title
        content
}
`;
export const fragComponentSectionsArticleListing = gql`
fragment ComponentSectionsArticleListing on ComponentSectionsArticleListing{
        title
        limitArticle
        category{
          ...BasicCategory
          articles (limit: 9,start:  0, sort: "publishAt:desc") {
           ...ArticleWithImage
          }
        }
        articles {
          ...ArticleWithImage
        }
}
${fragArticleWithImage}
`;
export const fragComponentSectionsPartnerListing = gql`
fragment ComponentSectionsPartnerListing  on ComponentSectionsPartnerListing  {
        title       
        enableSearch
}
`;